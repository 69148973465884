import Vue from 'vue'

export default {
  state: {
    feedbacks: {
      items: [],
      count: null
    },
    news: [],
    params: null,
    pages: {},
    components: {}
  },
  mutations: {
    setFeedbacks (state, feedbacks) {
      Vue.set(state, 'feedbacks', feedbacks)
    },
    setNews (state, news) {
      Vue.set(state, 'news', news)
    },
    setGlobal (state, params) {
      // eslint-disable-next-line no-debugger
      // debugger
      Vue.set(state, 'params', params.global)
      Vue.set(state, 'pages', params.pages.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
      Vue.set(state, 'components', params.components.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
    },
    setMaintenance (state, params) {
      // eslint-disable-next-line no-debugger
      // debugger
      Vue.set(state.params, 'maintenance', params.maintenance)
      // Vue.set(state, 'pages', params.pages.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
      // Vue.set(state, 'components', params.components.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}))
    },
  },
  actions: {
    async setAdminStatusMutation (ctx, params) {
      // console.log(params)
      await Vue.$api.setAdminStatus({ data: params })
    },
    async updateMaintenance (ctx) {
      const res = await Vue.$api.updateMaintenance()
      // eslint-disable-next-line no-debugger
      // debugger
      ctx.commit('setMaintenance', res?.data)
      return res?.data
    },
    async globalParams (ctx, params) {
      const res = await Vue.$api.globalParams({ segments: { lang: params } })
      ctx.commit('setGlobal', res?.data)
      return res?.data
    },
    async setRedirect (ctx, params) {
      const res = await Vue.$api.setRedirect({ data: params })
      return res.data
    },
    async fetchFeedbacks (ctx, params) {
      const res = await Vue.$api.fetchFeedbacks({ params })
      ctx.commit('setFeedbacks', res?.data)
      return res?.data.items
    },
    async fetchNews (ctx, params) {
      const res = await Vue.$api.fetchNews({ segments: { lang: params } })
      ctx.commit('setNews', res?.data.articles)
    },
    async addCardMutation (ctx, params) {
      const res = await Vue.$api.addCardMutation({ data: params })
      return res?.data
    }
  }
}
