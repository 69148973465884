import axios from 'axios'
import { Message } from 'element-ui';
import dictionary from '../../global/dictionary';
// import FingerprintJS from '@fingerprintjs/fingerprintjs';

const client = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE
})

// async function getFingerprint() {
//   const fp = await FingerprintJS.load();
//   const result = await fp.get({
//     extendedResult: true, // Получаем все данные, чтобы настроить компоненты
//     components: {
//       userAgent: true, // Статичный User-Agent
//       platform: false, // ОС (Windows, Mac, Linux)
//       language: false, // Язык браузера
//       screenResolution: false, // Разрешение экрана (стабильное)
//       cpuClass: false, // Архитектура процессора (стабильное)
//       timezone: false, // Часовой пояс (редко меняется)
      
//       // Отключаем переменные параметры
//       audio: false, 
//       canvas: false,
//       webgl: false,
//       fonts: false,
//       localStorage: false,
//       sessionStorage: false,
//       indexedDB: false,
//       openDatabase: false,
//       touchSupport: false,
//       colorDepth: false, 
//       deviceMemory: false, 
//     }
//   });
//   console.log(result)
//   return result.visitorId; // Уникальный fingerprint
// }

client.interceptors.request.use(async config => {
  const token = localStorage.getItem('accessToken'); // Получение токена из localStorage (или откуда у вас хранится)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Добавление заголовка Authorization
  }
  // const fingerprint = await getFingerprint();
  // config.headers['Fingerprint'] = fingerprint;
  config.headers['Fingerprint'] = "f3d584010cd22175cc58eff5e486bc61";

  return config;
})

client.interceptors.response.use(
  response => response, // Возвращаем ответ, если всё ок
  error => {
    if (error.response?.status === 429) {
      Message.error(dictionary.rateLimit);
    }
    if (error.response?.status === 403) {
      Message.error(dictionary.accessError);
    }
    return Promise.reject(error);
  }
);

export default client
